import React from 'react'
import Layout, { Footer } from '../components/Layout'
import { ArticleHeader, Article, Section, Dinkus } from '../components/Article'

const Page = () => {
  return (
    <Layout title="Sidan hittades inte" description="Sidan hittades inte">
      <Article>
        <ArticleHeader title="Sidan hittades inte" />
        <Section>
          <div>
            <div>Sidan du söker finns inte</div>
            <Dinkus>* * *</Dinkus>
            <footer>
              <p>
                Sciigo Sustainability Intelligence är en plattform för att samla
                och strukturera hållbarhetsdata för fastigheter, samt rapportera
                till styrelse och kreditgivare.{' '}
              </p>
              <p>
                Läs mer på <a href="/">sciigo.com</a> eller{' '}
                <a href="mailto:hello@sciigo.io">hör av dig</a>!
              </p>
            </footer>
          </div>
        </Section>
        <Footer>
          <p>
            <a href="/">sciigo.com</a>
          </p>
          <p>
            <a href="mailto:hello@sciigo.com">hello@sciigo.com</a>
          </p>
        </Footer>
      </Article>
    </Layout>
  )
}

export default Page
